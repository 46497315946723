import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
// Ui2
import Text from 'ui2/Text/Text';
// Styles
import 'react-responsive-modal/styles.css';
import styles from './Modal.module.scss';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
  focusTrapped?: boolean;
  className?: string;
  children?: JSX.Element;
  theme?: Theme;
  showCloseIcon?: boolean;
  modalStyles?: CSSProperties;
  // made closeIconClassName property for the invisible close icon on safari
  closeIconClassName?: string;
};

const Modal = ({
  title,
  subTitle,
  open,
  children,
  onClose,
  size = 'medium',
  focusTrapped = true,
  className,
  theme = 'black',
  showCloseIcon = true,
  modalStyles,
  closeIconClassName,
}: ModalProps) => {
  return (
    <ResponsiveModal
      center
      open={open}
      showCloseIcon={showCloseIcon}
      classNames={{
        overlay: styles.overlay,
        modal: cn(
          styles.modal,
          styles[`size-${size}`],
          styles[`theme-${theme}`],
          className
        ),
        closeIcon: cn(
          styles.closeIcon,
          styles[`size-${size}`],
          closeIconClassName
        ),
        closeButton: cn(styles.closeButton, styles[`size-${size}`]),
      }}
      styles={{
        modal: modalStyles,
      }}
      onClose={onClose}
      focusTrapped={focusTrapped}
    >
      {title && (
        <Text
          lowercase
          xxs={size === 'extra-small' ? 'small' : '4x-large'}
          textAlign={size === 'extra-small' ? undefined : 'center'}
          className={cn(styles.title, styles[`modal-size-${size}`], {
            [styles.withSubTitle]: subTitle,
          })}
        >
          {title}
        </Text>
      )}

      {subTitle && (
        <Text
          textAlign="center"
          color="light-white"
          className={styles.subTitle}
        >
          {subTitle}
        </Text>
      )}

      {children}
    </ResponsiveModal>
  );
};

export default Modal;
